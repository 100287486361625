import { RecommendationRequest } from './recommendation-request';
import { Readynode } from './readynode-response';

export class NodesSize {
    value: number;
}

export class VmsSize {
    value: number;
}

export class VmsPerNodeSize {
    value: number;
}

export class RawStorage {
    value: number;
    units: string;
}

export class EffectiveUsableStorage {
    value: number;
    units: string;
}

export class RawCache {
    value: number;
    units: string;
}

export class BestMatchReadyNodeProfile {
    value: string;
}

export class LicenseRequired {
    value: string;
}

export class PrevNodesSize {
    value: number;
}

export class RawCapacity {
    value: number;
    units: string;
}

export class RawStorageWithOverheads {
    value: number;
    units: string;
}

export class PrevCustomizeNodeSize {
    value: number;
}

export class TotalGuestIopsWithGrothFactor {
    value: number;
}

export class ClusterConfiguration {
    nodesSize: NodesSize;
    vmsSize: VmsSize;
    vmsPerNodeSize: VmsPerNodeSize;
    rawStorage: RawStorage;
    effectiveUsableStorage: EffectiveUsableStorage;
    rawCache: RawCache;
    bestMatchReadyNodeProfile: BestMatchReadyNodeProfile;
    licenseRequired: LicenseRequired;
    prevNodesSize: PrevNodesSize;
    rawCapacity: RawCapacity;
    rawStorageWithOverheads: RawStorageWithOverheads;
    prevCustomizeNodeSize: PrevCustomizeNodeSize;
    isFreeSpaceOverHeadUpdated: boolean;
    totalGuestIopsWithGrothFactor: TotalGuestIopsWithGrothFactor;
    strechedCluster: boolean;
    stretchedCluster: any;
    operationsReserve: any
}

export class CoresConsumedPerGuestIops {
    value: number;
}

export class TotalGuestIops {
    value: number;
}

export class TotalGuestIopsHFT {
    value: number;
}

export class TotalCoresConsumedvSAN {
    value: number;
}

export class TotalCoresConsumedvSANAndApp {
    value: number;
}

export class WeightedDistribution {
    value: number;
}

export class TotalVMsPerHostPerVMProfile {
    value: number;
}

export class MaxIopsPerDG {
    value: number;
}

export class IsIOPSBound {
    value: boolean;
}

export class UsableStorage {
    value: number;
    units: string;
}

export class MinClusterSize {
    value: number;
}

export class RaidOverhead {
    value: number;
}

export class Ram {
    value: number;
    units: string;
}

export class SlackSpace {
    value: number;
    units: string;
}

export class DedupOverhead {
    value: number;
    units: string;
}

export class DiskFormatOverhead {
    value: number;
    units: string;
}

export class PhysicalReservation {
    value: number;
    units: string;
}

export class ChecksumOverhead {
    value: number;
    units: string;
}

export class WorkloadProfileResponse {
    vmsSize: VmsSize;
    nodesSize: NodesSize;
    coresConsumedPerGuestIops: CoresConsumedPerGuestIops;
    totalGuestIops: TotalGuestIops;
    totalGuestIopsHFT: TotalGuestIopsHFT;
    totalCoresConsumedvSAN: TotalCoresConsumedvSAN;
    totalCoresConsumedvSANAndApp: TotalCoresConsumedvSANAndApp;
    weightedDistribution: WeightedDistribution;
    totalVMsPerHostPerVMProfile: TotalVMsPerHostPerVMProfile;
    maxIopsPerDG: MaxIopsPerDG;
    isIOPSBound: IsIOPSBound;
    usableStorage: UsableStorage;
    rawStorage: RawStorage;
    minClusterSize: MinClusterSize;
    raidOverhead: RaidOverhead;
    ram: Ram;
    slackSpace: SlackSpace;
    dedupOverhead: DedupOverhead;
    diskFormatOverhead: DiskFormatOverhead;
    physicalReservation: PhysicalReservation;
    rawStorageWithOverheads: RawStorageWithOverheads;
    checksumOverhead: ChecksumOverhead;
}

export class DiskGroups {
    value: number;
}

export class Memory {
    value: number;
    units: string;
}

export class Cores {
    value: number;
}

export class RequiredMemory {
    value: number;
    units: string;
}

export class CoresNoHeadRoom {
    value: number;
}

export class CapacityDrive {
    value: number;
}

export class VsanMemoryOverheadPerHost {
    value: number;
    units: string;
}

export class RequiredMemoryPerHost {
    value: number;
    units: string;
}

export class ServerConfigurationResponse {
    rawStorage: RawStorage;
    rawStorageWithOverheads: RawStorageWithOverheads;
    usableStorage: UsableStorage;
    rawCapacity: RawCapacity;
    diskGroups: DiskGroups;
    memory: Memory;
    rawCache: RawCache;
    cores: Cores;
    requiredMemory: RequiredMemory;
    coresNoHeadRoom: CoresNoHeadRoom;
    capacityDrive: CapacityDrive;
    vsanMemoryOverheadPerHost: VsanMemoryOverheadPerHost;
    requiredMemoryPerHost: RequiredMemoryPerHost;
}

export class CacheDriveSize {
    value: number;
    units: string;
}

export class EnduranceClass {
    value: string;
}

export class PerformanceClass {
    value: string;
}

export class SelectedCacheDriveSize {
    value: number;
    units: string;
}

export class DriveDistribution {
    diskGroup: number;
    numberOfDrives: number;
}

export class CacheSpecification {
    cacheDriveSize: CacheDriveSize;
    enduranceClass: EnduranceClass;
    performanceClass: PerformanceClass;
    selectedCacheDriveSize: SelectedCacheDriveSize;
    driveDistribution: DriveDistribution[];
}

export class CapacityDriveSize {
    value: number;
    units: string;
}

export class SelectedCapacityDriveSize {
    value: number;
    units: string;
}

export class Rpm {
    value: number;
}

export class CapacitySpecification {
    enduranceClass: EnduranceClass;
    performanceClass: PerformanceClass;
    capacityDriveSize: CapacityDriveSize;
    selectedCapacityDriveSize: SelectedCapacityDriveSize;
    driveDistribution: DriveDistribution[];
    rpm: Rpm;
}

export class DiskGroupConfiguration {
    cacheSpecification: CacheSpecification;
    capacitySpecification: CapacitySpecification;
}

export class RemainingFreeSpace {
    value: number;
    units: string;
}

export class DiskSpaceUsageOverheads {
    dedupOverhead: DedupOverhead;
    diskFormatOverhead: DiskFormatOverhead;
    checksumOverhead: ChecksumOverhead;
    physicalReservation: PhysicalReservation;
    slackSpace: SlackSpace;
    rawCapacity: RawCapacity;
    rawStorage: RawStorage;
    remainingFreeSpace: RemainingFreeSpace;
}

export class ObjectReplicationMethod {
    profileNumber: number;
    raidOption: string;
}

export class AdditionalInfo {
    controllerSpecification: string;
    vsanVersionUsed: string;
    objectReplicationMethod: ObjectReplicationMethod[];
    diskGroupInfo: boolean;
}

export class Cpu {
    value: number;
    units: string;
}

export class Iops {
    value: number;
    units: string;
}

export class Capacity {
    value: number;
    units: string;
}

export class ResourceStats {
    cpu: Cpu;
    iops: Iops;
    capacity: Capacity;
    memory: Memory;
}

export class ManagementServer {
    value: number;
}

export class RackCount {
    value: number;
}

export class MgmtSwitchCount {
    value: number;
}

export class TorSwitchCount {
    value: number;
}

export class SpinSwitchCount {
    value: number;
}

export class Switches {
    mgmtSwitchCount: MgmtSwitchCount;
    torSwitchCount: TorSwitchCount;
    spinSwitchCount: SpinSwitchCount;
}

export class ResourceValues {
    previousCores: number;
    previousIops: number;
    previousCapacity: number;
    previousMemory: number;
    additionalHost: number;
}

export class RecommendationResponse {
    clusterConfiguration: ClusterConfiguration;
    workloadProfiles: WorkloadProfileResponse[];
    serverConfiguration: ServerConfigurationResponse;
    diskGroupConfiguration: DiskGroupConfiguration;
    diskSpaceUsageOverheads: DiskSpaceUsageOverheads;
    additionalInfo: AdditionalInfo;
    sizingAssumptions: string[];
    resourceStats: ResourceStats;
    managementServer: ManagementServer;
    rackCount: RackCount;
    switches: Switches;
    resoruceValues: ResourceValues;
    tanzuRecommResp?: {
        k8sClusters: [],
        totalSummary: {
            totalClusterMemoryPerVM: Number
            totalClusterStorage: Number
            totalClustervCPUPerVM: Number
            totalNodes: Number
        }
    };
}

export class RecommendationPDF {
    recommdendationReq: RecommendationRequest;
    recommendationRes: RecommendationResponse;
    projectName: string;
    vmCustomerId: string;
    readyNodeResponse: Readynode;
}
