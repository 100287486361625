// tslint:disable: max-line-length
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { REDIRECT_URI, STATE } from '../app.config';
import { AuthProvider } from './authProvider.service';
import { environment } from '../../environments/environment';
import { EnvironmentsService } from './environments.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private SESSION_KEY = 'vcf-planner-client-session';
  private jwtService: JwtHelperService;
  private currentSession: BehaviorSubject<any>;
  private userNameSource = new BehaviorSubject('Guest');
  userName = this.userNameSource.asObservable();
  alertUserModal: boolean = false;

  constructor(private http: HttpClient, private authProvider: AuthProvider,private env:EnvironmentsService) {
    this.jwtService = new JwtHelperService();
    let session = null;
    try {
      session = JSON.parse(localStorage.getItem(this.SESSION_KEY));
    } catch (e) {
    }
    this.currentSession = new BehaviorSubject(session);
  }

  changeUser(user: string) {
    if(this.getUser().provider === 'workspace_one'){
      (<any>window)._paq.push(['trackEvent', 'Login Type', 'Workspace Login' + "(User:" + this.getUser().username + ")"]);
    }else if(this.getUser().provider === 'partner_central'){
      (<any>window)._paq.push(['trackEvent', 'Login Type', 'Partner Login' + "(User:" + this.getUser().username + ")"]);
    }else if(this.getUser().provider === 'myvmware'){
      (<any>window)._paq.push(['trackEvent', 'Login Type', 'MyVMWare Login' + "(User:" + this.getUser().username + ")"]);
    }
    this.userNameSource.next(user);
  }

  getCurrentSession(): BehaviorSubject<any> {
    return this.currentSession;
  }

  getUser() {
    const session = this.currentSession.getValue();
    return session ? session.user : null;
  }

  getAccessToken() {
    const session = this.currentSession.getValue();
    return session ? session.access_token : null;
  }

  getRefreshToken() {
    const session = this.currentSession.getValue();
    return session ? session.refresh_token : null;
  }

  saveCurrentSession(session) {
    session.user = this.jwtService.decodeToken(session.access_token);
    localStorage.setItem(this.SESSION_KEY, JSON.stringify(session));
    this.currentSession.next(session);
    this.alertUserModal = this.forceUserToLoginWithBroadcomDotNet();
  }

  clearCurrentSession() {
    localStorage.removeItem(this.SESSION_KEY);
    this.currentSession.next(null);
  }

  isTokenExpired(token: string) {
    return this.jwtService.isTokenExpired(token);
  }

  logout() {
    this.http.post(`${this.authProvider.getApiUrl()}/logout`, null).pipe(
      finalize(() => {
        this.clearCurrentSession();
        this.redirectToLogin();
      })
    ).subscribe();
  }
  setFeedBackUser() {
    /*const userEmail = this.getUser().email;
    localStorage.setItem('esp-feedback-user', userEmail);
    const script = document.createElement('script');
    script.innerHTML = `esp.identify('${userEmail}')`;
    document.head.appendChild(script);*/
    const user = this.getUser();

    if (user) {
      localStorage.setItem('esp-feedback-user', user.username);
      const script = document.createElement('script');
      script.innerHTML = `lumos.identify('${user.username}')`;
      if (window['lumos']) {
        window['lumos'].identify(user.username);
      }
      document.head.appendChild(script);
    }
  }

  redirectToLogin() {
    const redirectUri = window.location.origin + REDIRECT_URI;
    window.location.href = `${this.authProvider.getApiUrl()}/csp/gateway/discovery?response_type=code&client_id=${this.env.config.CLIENT_ID}&state=${this.env.config.STATE}&redirect_uri=${redirectUri}&orgLink=/csp/gateway/am/api/orgs/${this.env.config.orgId}&code_challenge=${this.env.config.codeChallenge}&code_challenge_method=S256`;
  }
  //   if (this.env.config.environmentType !== null && this.env.config.environmentType !== undefined && this.env.config.environmentType === 'prod') {
  //     window.location.href = `${this.authProvider.getApiUrl()}/authorize?response_type=code&client_id=${this.env.config.CLIENT_ID}&state=${STATE}&redirect_uri=${redirectUri}`;
  //   } else {
  //     window.location.href = `${this.authProvider.getApiUrl()}/authorize?response_type=code&client_id=${this.env.config.CLIENT_ID}&state=${STATE}&redirect_uri=${redirectUri}&bypass_uri_check=true`;
  //   }
  // }

  redirectToHome() {
    const redirectUri = window.location.origin + '/';
    window.location.href = `${redirectUri}`;
  }

  onLogout(){
    localStorage.removeItem("isNotAuthorized");
    this.clearCurrentSession();
  }

  getUserName(){
    if(this.currentSession.getValue() != null && this.currentSession.getValue().id_token != null){
        return this.jwtService.decodeToken(this.currentSession.getValue().id_token).given_name;
    }
  }

   getIdAccessToken(): string | null {
    const session = this.currentSession.getValue();
    return session ? session.id_token : null;
  }

  forceUserToLoginWithBroadcomDotNet(): boolean {
    let decodedIdToken = this.jwtService.decodeToken(this.getIdAccessToken());
    let acct: string = decodedIdToken?.acct ? decodedIdToken.acct : '';
    const acctParts = acct?.split('@');
    if (decodedIdToken?.domain === 'vmwareid' && (acctParts?.length >= 2 && acctParts[1] === 'broadcom.com')) {
      return true;
    }
    return false;
  }
  
}
