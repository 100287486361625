import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AppStringsService } from 'src/app/services/app-strings/app-strings.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  @Input() openModal: boolean;
  @Output() closeModal = new EventEmitter<boolean>();
  currentSection: string;
  appLabels: any;
  collapsed: boolean;
  constructor(public appstringService: AppStringsService) { }

  ngOnInit() {
    this.currentSection = 'faqs';
    this.appLabels = this.appstringService.appStrings;
    this.collapsed = true;
  }
  closeHelpModal(){
    this.closeModal.emit(false);
  }
  activateSection(sectionSelected: string){
    this.currentSection = sectionSelected;
  }
}
