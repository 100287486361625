// tslint:disable: max-line-length
export const environment = {
  apiUrl: 'https://auth.esp.vmware.com/api/auth/v1',
  inputServiceUrl: 'https://eapi.broadcom.com/vcf/tools/input',
  rvToolUrl: 'https://eapi.broadcom.com/vcf/tools/input',
  recommendationServiceUrl: 'https://eapi.broadcom.com/vcf/tools/recomm',
  sfdcUrl : 'https://eapi.broadcom.com/vcf/tools/cmds',
  CLIENT_ID: 'esp-prod-88-cjvlw',
  environmentType: 'prod',
  trackingId: 'UA-166274977-1',
  featureModule: 'saved-configurations',
  orgId : 'fb3d8325-fa40-4c92-83de-de54c084fbfe',
  codeChallenge: '7GqmC3rOWGkyEDBlxSVh6xsLi0LZcpnMgtRps_B0Mw8',
  codeChallengeMethod:'S256',
  codeVerifier:'u1adRVd1-dUNkDWcjPF07VOedFXJSX9vH6wu3NO8fP2sADqDcOUM-8Tp_WiT73iUE9m3o8bxYQn2SDp0B_4ZZOj3nY5ctpwWrdlJZeBsvJSsXfm1aOhugnh2v69bbmeV',
  authApiUrl: 'https://console.cloud.vmware.com',
  STATE: 'CXNwLWFwaS1jbGllbnQ=',
};
