import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MyProjectsProvider } from './myProjectsProvider.service';
import { MySavedProject } from '../models/my-saved-project.model';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
// tslint:disable: max-line-length
export class MyProjectsService {
  private url: string;
  private generateJSONSubscription: Subscription;

  eulaCertificate;
  constructor(private http: HttpClient, private myProjectsProvider: MyProjectsProvider) {
    this.url = this.myProjectsProvider.getApiUrl();
  }

  getSavedProjectList(user: string) {
    return this.http.get(`${this.url}/vcf/planner/v1/projects/${user}`);
  }
  

  createProject(request: MySavedProject) {
    return this.http.post(`${this.url}/vcf/planner/v1/project`, request);
  }

  updateProject(request: MySavedProject) {
    return this.http.put(`${this.url}/vcf/planner/v1/project`, request);
  }

  deleteProject(projectId: number) {
    return this.http.delete(`${this.url}/vcf/planner/v1/projects/${projectId}`);
  }

  resetProject(projectId: number) {
    return this.http.delete(`${this.url}/vcf/planner/v1/projects/metadata/${projectId}`);
  }

  getProjectById(projectId: number) {
    return this.http.get(`${this.url}/vcf/planner/v1/projects/id/${projectId}`);
  }


  generateJson(projectId: any, projectName: string) {
    this.generateJSONSubscription = this.http.get(`${this.url}/vcf/planner/v1/downloadbom/${projectId}`).subscribe(
      (response: any) => {
        // tslint:disable-next-line: prefer-const
        let json = JSON.stringify(response, null, 2);
        const binaryData = [];
        binaryData.push(response);
        if (binaryData.length > 0) {
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob([json], { type: 'application/json' }));
          downloadLink.setAttribute('download', projectName + '-bom.json');
          document.body.appendChild(downloadLink);
          downloadLink.click();

        } else {
          console.log('Error in downloading');
        }

      }
    );
  }
  generateJsonConsolidated(projectId: any, projectName: string) {
    this.generateJSONSubscription = this.http.get(`${this.url}/vcf/planner/v1/download/consolidated/bom/${projectId}`).subscribe(
      (response: any) => {
        // tslint:disable-next-line: prefer-const
        let json = JSON.stringify(response, null, 2);
        const binaryData = [];
        binaryData.push(response);
        if (binaryData.length > 0) {
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob([json], { type: 'application/json' }));
          downloadLink.setAttribute('download', projectName + '-bom.json');
          document.body.appendChild(downloadLink);
          downloadLink.click();

        } else {
          console.log('Error in downloading');
        }

      }
    );
  }

}
