import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';
import { EnvironmentsService } from 'src/app/services/environments.service';
import { UserService } from 'src/app/services/user.service';
import { Subject } from 'rxjs';
import { REDIRECT_URI } from 'src/app/app.config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly DEFAULT_RETURN_URL = '/';

  private readonly OAUTH_GRANT_TYPES = {
    authorizationCode: 'authorization_code',
    refreshToken: 'refresh_token',
  };

  private config: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private envService: EnvironmentsService,
    private userService: UserService
  ) {
    // const user = this.userService.getUser();
    // this.loggedUser.next(user);
    this.config = this.envService.config;
  }

  fetchTokens(code: string) {
    const params = new HttpParams({
      fromObject: {
        grant_type: this.OAUTH_GRANT_TYPES.authorizationCode,
        code: code,
        code_verifier: this.envService.config.codeVerifier,
        redirect_uri: `${window.location.origin}${REDIRECT_URI}`,
      },
    });


    const headers = this.getHeader();

    const urllog = `${this.config.authApiUrl}`;
    
    return this.http.post(
      `${this.config.authApiUrl}/csp/gateway/am/api/auth/authorize`,
      params,
      { headers: this.getHeader() }
    );
  }


  private getHeader() {
    const clientid = `${this.envService.config.CLIENT_ID}`;

    return new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded')
      .set(
        'Authorization',
        'Basic ' + btoa(`${this.envService.config.CLIENT_ID}` + ':' + '')
      );
  }

  logout() {

    const params = new HttpParams({
      fromObject: {
        token_type_hint: 'refresh_token',
        token: this.userService.getRefreshToken() as string
      },
    });
   

    this.http
      .post(`${this.config.authApiUrl}/csp/gateway/am/api/auth/revoke`, 
        params,
      { headers: this.getHeader() 
      })
      .pipe(
        switchMap(() => {
          return this.http.post(`${this.config.authApiUrl}/csp/gateway/am/api/auth/logout`, {
            idToken: this.userService.getIdAccessToken()
          });
        }),
        catchError((err) =>
          this.router.navigate([`error/500`], { skipLocationChange: true })
        )
      )
      .subscribe((logoutResponse:any) => {
        this.userService.onLogout();
        window.location.href = logoutResponse['url'];
      });
  }

}
