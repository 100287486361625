import { Component, OnInit, HostListener } from '@angular/core';
import { UserService } from './services/user.service';
import { Router } from '@angular/router';
import { ProjectsService } from './services/projects.service';
import {Chart} from 'chart.js';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthService } from 'src/app/components/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loading = false;
  updateVersion = false;
  showBanner: boolean = false;
    // Define the end date to show the banner (Year, Month-1, Day)
  private endDate: Date = new Date(2025, 2, 14); // Example: December 15, 2024
  constructor(public userService: UserService,private bnIdle: BnNgIdleService, private router: Router , private project:ProjectsService, private authService:AuthService) { 
  //  sessionTimeout 15 minutes
  this.bnIdle.startWatching(1500).subscribe((res) => {
    if(res) {
      this.authService.logout();
    }
  })
  }
  @HostListener('window:scroll', ['$event']) 
    myFunction(e) {
      if(document.querySelector('.scroll_event')){
        let header = document.querySelector(".scroll_event") as HTMLElement;
        let sticky = header.offsetTop;
        if (document.querySelector('.content-area').scrollTop > 690) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      }
    }
  ngOnInit() {
    if (location.href.endsWith('new-configuration') || location.href.endsWith('reverse-sizing') || location.href.endsWith('one-pod')) {
      this.router.navigateByUrl('');
    }
    this.project.load.subscribe(resp  => {
      setTimeout(() => {
        this.loading = resp;
      }, 0);
    })
    this.project.updateversion.subscribe(resp=>{
      setTimeout(() => {
        this.updateVersion = resp;
      }, 0);
    })
    let espMode;    
    if (this.isProdEnv()) {
      espMode = 'production'
    } else {
      espMode = 'staging'
    }
    localStorage.setItem('esp-mode', espMode);
    
    this.userService.setFeedBackUser();
    //chart te center
    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.type == 'doughnut' && chart.options.title) {
          var centerConfig = chart.config.options.title;
          var ctx = chart.ctx;
          var fontStyle = centerConfig.fontFamily || 'Arial';
          var txt = centerConfig.text.toString();
          var color = centerConfig.fontColor || '#000';
          var sidePadding = centerConfig.padding || 20;
          var innerRadius = chart.getDatasetMeta(0).controller["innerRadius"];
          var sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2);
          //Start with a base font of 30px
          ctx.font = "20px " + fontStyle;
          //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          var stringWidth = ctx.measureText(txt).width;
          var elementWidth = (innerRadius * 2) - sidePaddingCalculated;
          // Find out how much the font can grow in width.
          var widthRatio = elementWidth / stringWidth;
          var newFontSize = Math.floor(20 * widthRatio);
          var elementHeight = (innerRadius * 2);
          // Pick a new font size so it will not be larger than the height of label.
          var fontSizeToUse = Math.min(newFontSize, elementHeight);
          //Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
          ctx.font = 10 + "px " + fontStyle;
          ctx.fillStyle = color.toString();
          //Draw text in center
          ctx.fillText(txt, centerX, centerY);
        }
      }
    });

    if(this.userService.forceUserToLoginWithBroadcomDotNet()) {
      this.userService.alertUserModal = true;
    }
    
  }
  isProdEnv() {
    return window.location.origin.includes('vcf.broadcom.com/tools/vcfplanner');
  }


  checkShowBanner() {
    const today = new Date();
    // Check if today is before or on the end date
    this.showBanner = today <= this.endDate;
    return this.showBanner;
  }

  logout() {
    this.authService.logout();
  }

  copyText: string = 'https://vcf.broadcom.com/tools/vcfplanner';

  // Function to copy text to clipboard
  copyToClipboard() {
    const text = this.copyText;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Copied to clipboard!');
      }).catch((err) => {
        console.error('Failed to copy text:', err);
      });
    } else {
      alert('Clipboard API not supported!');
    }
  }
}
