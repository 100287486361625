import { Component, OnInit, Renderer2 } from '@angular/core';
import { AppStringsService } from 'src/app/services/app-strings/app-strings.service';
import { UserService } from 'src/app/services/user.service';
import { MyProjectsService } from 'src/app/services/my-projects.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/components/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  appLabels: any;
  openHelpModal: boolean = false;
  openAccountModal: boolean = false;
  releaseNotes = false;
  constructor(public appStringLabels: AppStringsService, private renderer: Renderer2, public userService: UserService, public projectservice :MyProjectsService,private router: Router, private authService:AuthService) { }

  ngOnInit() {
    this.appLabels = this.appStringLabels.appStrings;
  }
  addClass(){
    this.renderer.addClass(document.querySelector('#dropdown-menu'), 'open')
  }
  removeClass(){
    this.renderer.removeClass(document.querySelector('#dropdown-menu'), 'open')
  }
  helpModalHandler(value: boolean){
    this.openHelpModal = value;
  }
  releaseModalHandler(value: boolean){
    this.releaseNotes = value;
  }
  accountModalHandler(value: boolean){
    this.openAccountModal = value;
  }
  userName() {
    if(this.userService.getUserName())
   return this.userService.getUserName()
   else{
     if(this.userService.getUser()){
       return this.userService.getUser().username
       
     }else
     setTimeout(() => {
       this.userName()
     }, 1000);
   }
  }
  signOut(){
    this.authService.logout();
  }
  onReleaseNotesClick(){
    this.router.navigateByUrl('/release-notes')
  }
  addClassForHelp(){
    this.renderer.addClass(document.querySelector('#dropdown-menu1'), 'open')
  }
  removeClassForHelp(){
    this.renderer.removeClass(document.querySelector('#dropdown-menu1'), 'open')
  }
  onFAQClick(){
    this.router.navigateByUrl('/faq')
  }
  onSupportClick(){
    this.router.navigateByUrl('/support')
  }
}
