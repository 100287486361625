import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { filter, switchMap, take, finalize, catchError, tap } from 'rxjs/operators';

import { UserService } from './user.service';
import { ApiService } from './api.service';
import { ProjectsService } from './projects.service';
import { EnvironmentsService } from './environments.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    refreshingToken: boolean;
    count = 0;
    constructor(private userService: UserService, private apiService: ApiService, private project: ProjectsService, private envService: EnvironmentsService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.project.setLoader(true);
        this.count++;
        if (!req.url.endsWith('/tokens') && !req.url.endsWith('/authorize') && !req.url.endsWith('/revoke') && !req.url.endsWith('/logout'))  {
            if (this.refreshingToken) {
                return this.userService.getCurrentSession().pipe(
                    filter(session => session !== null),
                    take(1),
                    switchMap((session) => next.handle(this.addAuthorization(req, session.id_token))),
                    finalize(() => {
                        this.count--;
    
                        if (this.count === 0) {
                            this.project.setLoader(false);
                        }
                    })

                );
            }
            const idToken = this.userService.getIdAccessToken();
            if (!idToken) {
                return next.handle(req).pipe(tap(

                    event => (event),

                    error => (error)

                ), finalize(() => {
                    this.count--;

                    if (this.count === 0) {
                        this.project.setLoader(false);
                    }
                })
                );
            }
            if (this.userService.isTokenExpired(idToken)) {
                this.refreshingToken = true;
                const refreshToken = this.userService.getRefreshToken();
                this.userService.clearCurrentSession();
                return this.apiService.refreshToken(refreshToken).pipe(
                    finalize(() => {
                        this.refreshingToken = false;
                        this.count--;

                        if (this.count === 0) {
                            this.project.setLoader(false);
                        }
                    }),
                    switchMap(
                        (session: any) => {
                            this.userService.saveCurrentSession(session);
                            return next.handle(this.addAuthorization(req, session.id_token));
                        }
                    ),
                    catchError(error => {
                        this.userService.redirectToLogin();
                        return throwError(error);
                    })
                );
            } else {
                return next.handle(this.addAuthorization(req, idToken)).pipe(tap(

                    event => (event),

                    error => (error)

                ), finalize(() => {
                    this.count--;

                    if (this.count === 0) {
                        this.project.setLoader(false);
                    }
                })
                );
            }
        } else {
            if(req.url.endsWith('/authorize')|| req.url.endsWith('/revoke') || req.url.includes('/logout')){
                const authenticatedRequest = req.clone({
                    headers: req.headers.set(
                      'Authorization',
                      'Basic ' + btoa(`${this.envService.config.CLIENT_ID}` + ':' + '')
                    ).delete('Cache-Control')
                  });
                
                this.count--;

                if (this.count === 0) {
                    this.project.setLoader(false);
                }
                  // Request with authorization headers
                  return next.handle(authenticatedRequest);
            }
            return next.handle(req).pipe(tap(

                event => (event),

                error => (error)

            ), finalize(() => {
                this.count--;

                if (this.count === 0) {
                    this.project.setLoader(false);
                }
            })
            );

        }
    }

    addAuthorization(req: HttpRequest<any>, id_token?: string): HttpRequest<any> {
        return req.clone({ setHeaders: { 
            Authorization: `Bearer ${id_token}`
        } 
    });
    }
}
