export class VmdkSize {
    value: number;
    units: string;
}

export class Raid {
    value: string;
}

export class VcpuPerCore {
    value: number;
}

export class VcpuPerVM {
    value: number;
}

export class VmemPerVM {
    value: number;
    units: string;
}

export class IopsPerVM {
    value: number;
}

export class HostFailures {
    value: number;
}

export class DedupRatio {
    value: number;
}

export class VmsCount {
    value: number;
}

export class ExpectedCloneGrowth {
    value: number;
    units: string;
}

export class InstantClonePools {
    value: number;
}

export class VmProfile {
    vmdkSize: VmdkSize;
    raid: Raid;
    vcpuPerCore: VcpuPerCore;
    vcpuPerVM: VcpuPerVM;
    vmemPerVM: VmemPerVM;
    iopsPerVM: IopsPerVM;
    hostFailures: HostFailures;
    dedupRatio: DedupRatio;
    compression: DedupRatio;
    compressionOnly: ReduceRedundancy;
    masterVMsize: MasterVMsize;
    disksInMasterVM: DisksInMasterVM;
    linkedClonePool: LinkedClonePool;
    vmdkGrowthFactor: VmdkGrowthFactor;
    persistentDiskSize: PersistentDiskSize;
    sddSize: SddSize;
    vmsCount: VmsCount;
    expectedCloneGrowth: ExpectedCloneGrowth;
    instantClonePools: InstantClonePools;
}

export class IoAccessPattern {
    value: string;
}

export class IoSize {
    value: number;
    units: string;
}

export class IoProfile {
    ioAccessPattern: IoAccessPattern;
    ioSize: IoSize;
    ioRatios: IoRatio;
}

export class ReduceRedundancy {
    value: boolean;
}

export class HostFailure {
    reduceRedundancy: ReduceRedundancy;
}

export class UtilizationMode {
    value: string;
}

export class VmPercentage {
    value: number;
    units: string;
}

export class CpuUtilization {
    value: number;
    units: string;
}

export class CpuConsumption {
    value: number;
    units: string;
}

export class ResourceUtilizationPlan {
    utilizationMode: UtilizationMode;
    vmPercentage: VmPercentage[];
    cpuUtilization: CpuUtilization[];
    cpuConsumption: CpuConsumption;
}

export class CoresConsumed {
    value: any;
}

export class MaxIOpsPerDisk {
    value: any;
}

export class VSANIOAmplifier {
    value: number;
}

export class MaxBandwidthPerDisk {
    value: number;
    units: string;
}

export class VSANOverheads {
    coresConsumed: CoresConsumed;
    maxIOpsPerDisk: MaxIOpsPerDisk;
    capacityWriteIOAmplifier: VSANIOAmplifier;
    maxBandwidthPerDisk: MaxBandwidthPerDisk;
    cacheWriteIOAmplifier: VSANIOAmplifier;
}

export class MasterVMsize {
    value: number;
    units: string;
}

export class DisksInMasterVM {
    value: number;
}

export class LinkedClonePool {
    value: number;
}

export class VmdkGrowthFactor {
    value: number;
    units: string;
}

export class PersistentDiskSize {
    value: number;
    units: string;
}

export class SddSize {
    value: number;
    units: string;
}

export class VcfDefaultConfig {
    vmProfile: VmProfile;
    ioProfile: IoProfile;
    hostFailure: HostFailure;
    resourceUtilizationPlan: ResourceUtilizationPlan;
    vSANOverheads: VSANOverheads;
}

export class VmsCountLimits {
    min: number;
    max: number;
}

export class VmdkSizeLimits {
    min: number;
    max: number;
    units: string;
}

export class RaidLimit {
    value: string;
    description: string;
}

export class VcpuPerCoreLimits {
    min: number;
    max: number;
}

export class VcpuPerVMLimits {
    min: number;
    max: number;
}

export class VmemPerVMLimits {
    min: number;
    max: number;
    units: string;
}

export class IopsPerVMLimits {
    min: number;
    max: number;
}

export class DedupRatioLimits {
    min: number;
    max: number;
}

export class HostFailuresLimit {
    value: number;
}

export class ExpectedCloneGrowthLimits {
    min: number;
    max: number;
    units: string;
}

export class InstantClonePoolsLimits {
    min: number;
    max: number;
}

export class VmProfileLimits {
    vmsCountLimits: VmsCountLimits;
    vmdkSizeLimits: VmdkSizeLimits;
    raidLimits: RaidLimit[];
    vcpuPerCoreLimits: VcpuPerCoreLimits;
    vcpuPerVMLimits: VcpuPerVMLimits;
    vmemPerVMLimits: VmemPerVMLimits;
    iopsPerVMLimits: IopsPerVMLimits;
    dedupRatioLimits: DedupRatioLimits;
    hostFailuresLimits: HostFailuresLimit[];
    masterVMsizeLimits: MasterVMsizeLimits;
    diskInMasterVMLimits: DiskInMasterVMLimits;
    linkedClonePoolLimits: LinkedClonePoolLimits;
    vmdkGrowthFactorLimits: VmdkGrowthFactorLimits;
    persistentDiskSizeLimits: PersistentDiskSizeLimits;
    sddSizeLimits: SddSizeLimits;
    expectedCloneGrowthLimits: ExpectedCloneGrowthLimits;
    instantClonePoolsLimits: InstantClonePoolsLimits;
}

export class IoRatio {
    value: string;
}

export class IoProfileLimits {
    ioAccessPattern: IoAccessPattern[];
    ioSize: IoSize[];
    ioRatios: IoRatio[];
}

export class ReduceReduncy {
    value: boolean;
}

export class HostFailureLimits {
    reduceReduncy: ReduceReduncy[];
}

export class VmPercentageLimits {
    min: number;
    max: number;
    units: string;
}

export class CpuUtilizationLimits {
    min: number;
    max: number;
    units: string;
}

export class CpuConsumptionLimits {
    min: number;
    max: number;
    units: string;
}

export class ResourceUtilizationPlanLimits {
    vmPercentageLimits: VmPercentageLimits;
    cpuUtilizationLimits: CpuUtilizationLimits;
    cpuConsumptionLimits: CpuConsumptionLimits;
}

export class CoresConsumedLimits {
    min: number;
    max: number;
}

export class MaxIOpsPerDiskLimits {
    min: number;
    max: number;
}

export class MaxBandwidthPerDiskLimits {
    min: number;
    max: number;
    units: string;
}

export class VSANIOAmplifierLimits {
    min: number;
    max: number;
}

export class VSANOverheadsLimits {
    coresConsumedLimits: CoresConsumedLimits;
    maxIOpsPerDiskLimits: MaxIOpsPerDiskLimits;
    maxBandwidthPerDiskLimits: MaxBandwidthPerDiskLimits;
    vSANIOAmplifierLimits: VSANIOAmplifierLimits;
    capacityWriteIOAmplifierLimits: VSANIOAmplifierLimits;
    cacheWriteIOAmplifierLimits: VSANIOAmplifierLimits;
}

export class MasterVMsizeLimits {
    min: number;
    max: number;
    units: string;
}

export class DiskInMasterVMLimits {
    min: number;
    max: number;
}

export class LinkedClonePoolLimits {
    min: number;
    max: number;
}

export class VmdkGrowthFactorLimits {
    min: number;
    max: number;
    units: string;
}

export class PersistentDiskSizeLimits {
    min: number;
    max: number;
    units: string;
}

export class SddSizeLimits {
    min: number;
    max: number;
    units: string;
}

export class VcfLimitConfig {
    vmProfileLimits: VmProfileLimits;
    ioProfileLimits: IoProfileLimits;
    hostFailureLimits: HostFailureLimits;
    resourceUtilizationPlanLimits: ResourceUtilizationPlanLimits;
    vSANOverheadsLimits: VSANOverheadsLimits;
    capacityWriteIOAmplifierLimits: VSANOverheadsLimits;
    cacheWriteIOAmplifierLimits: VSANOverheadsLimits;
}

export class TanzuVmProfileLimits {
    vcpuPerVMLimits: { min: number, max: number };
    vmemPerVMLimits: { min: number, max: number, units: string };
    appStorageLimits: { min: number, max: number, units: string };
}
export class TanzuLimitConfig {
    vmProfile: TanzuVmProfileLimits;
}

export class WorkloadConfig {
    vcfDefaultConfig: VcfDefaultConfig;
    vcfLimitConfig: VcfLimitConfig;
    tanzuCustomSizeConfig: TanzuLimitConfig;
}


