import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RecommendationProvider } from './recommendationProvider.service';
import { RecommendationRequest } from '../models/recommendation-request';
import { ReadyNodeRequest } from '../models/readynode-request';
import { Observable, EMPTY } from 'rxjs';
import { RecommendationResponse } from '../models/recommendation-response';
import { Readynode } from '../models/readynode-response';
import { UserService } from './user.service';
import { EnvironmentsService } from './environments.service';

@Injectable({
    providedIn: 'root'
})
// tslint:disable: max-line-length
export class RecommendationService {
    private url: string;

    public recommendationRequestDataForPDF: RecommendationRequest;
    public recommendationResponseDataForPDF: RecommendationResponse;
    public projectName: string;
    public vmCustomerId: string;
    public perNodeLevelCOnfigForPDF: any;
    public pdfString: any;
    public dsdBreakDownGraph: any;
    public mgmtDsdBreakDownGraph: any;
    public managementoutput: any;
    public resourceUtilization: any;
    public logicBound: any;
    public tanzuWorkerNodeVCPUCharts: any;
    public tanzuWorkerNodeMemoryCharts: any;
    public vmSizingDetails: any;
    sfdcUrl : string;

    constructor(private http: HttpClient, private recommendationProvider: RecommendationProvider, private userService: UserService, private env :EnvironmentsService) {
        this.url = this.recommendationProvider.getApiUrl();
        this.sfdcUrl = this.recommendationProvider.getsfdcUrl();
    }

    getRecommendation(request: RecommendationRequest) {
        return this.http.post(`${this.url}/vcf/planner/v1/recommendation`, request);
    }

    getRecommendationByResource(request: RecommendationRequest) {
        return this.http.post(`${this.url}/vcf/planner/v1/recommendation/customization/resource`, request);
    }

    getReadyNodeRecommendation(request: ReadyNodeRequest) {
        return this.http.post(`${this.url}/vcf/planner/v1/readyNodeRecommendation`, request);
    }

    getSFDCCustomerDetails(id): Observable<any> {
        if (id !== null && id !== '') {
            return this.http.post<any>(`${this.sfdcUrl}/v1/customers/search?source=oracle`,id)
        } else { return EMPTY; }
    }

    shareProject(formData) {
        return this.http.post(this.url + '/vcf/planner/v1/share/blobfile', formData);
    }
    getServerDataForReadynode(request: Readynode) {
        return this.http.post(`${this.url}/vcf/planner/v1/readynode/serverconfig`, request);
    }
    updateMangement(request: any, id) {
        return this.http.put(`${this.url}/vcf/planner/v1/project/id/` + id, request);
    }
    updateWorkload(request: any) {
        return this.http.put(`${this.url}/vcf/planner/v1/project/metadata`, request);
    }
    deleteWorkload(workloadId, projectId) {
        return this.http.delete(this.url + '/vcf/planner/v1/project/metadata/' + workloadId + '?pid=' + projectId)
    }
    getupdatedDetails(projectId) {
        return this.http.get(this.url + '/vcf/planner/v1/projects/id/' + projectId)
    }
    updatemanagementdata(projectdata) {
        let mangementUpdateData = projectdata;
        delete mangementUpdateData.savedProjectMetaDataList;
        mangementUpdateData.updatedBy = this.userService.getUser().acct;
        mangementUpdateData.updatedAt = new Date();
        return mangementUpdateData;
    }
    consolidatedInput(data) {
        return this.http.post(this.url + '/vcf/planner/v1/consolidatedrecommendation', data)
    }
    resorceCustomization(data) {
        return this.http.post(this.url + '/vcf/planner/v1/consolidated/recommendation/customization/resource', data)
    }
    /**
     * Get Tanzu Recommendation
     * @param request 
     * @returns 
     */
    getTanzuRecomm(request) {
        return this.http.post(`${this.url}/vcf/planner/v1/tanzu/clusters/tanzurecommendation`, request);
    }
    matchUsers(keyword: string): Observable<any> {
        const removeDomain = keyword.includes("@") ? keyword.split('@')[0] : keyword;
        
        return this.http.get<Array<{email:string, firstName: string, lastName: string, domain: string, username: string}>>(this.url + '/vcf/planner/v1/user/search?searchTerm=' + removeDomain.trim())
    }
}
