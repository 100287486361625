import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { ControlIdService } from '@clr/angular/forms/common/providers/control-id.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  infoMessage: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userApiService: UserService
  )
  {}

  ngOnInit() {
    this.route.queryParams
      .pipe(
        map((params: any) => params),
        mergeMap((data: any) => {
          const code = data.code;
          const state = data.state;
          
          return this.authService.fetchTokens(code);
        })
      )
      .subscribe(
        (response: any) => {
          this.userApiService.saveCurrentSession(response);
          this.router.navigateByUrl('/');
        },
        (error) => {
          this.infoMessage = 'Can not process login request.';
          console.error(error);
        }
      );
  }
}
