import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
// import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from 'src/app/services/auth.guard';
import { LogoutComponent } from './components/logout/logout.component';
import { AuthCallbackComponent } from './components/auth/auth-callback/auth-callback.component';
// tslint:disable: max-line-length
const routes: Routes = [
  // { path: 'login/authenticate', component: LoginComponent },
  { path: 'logout', component:LogoutComponent },
  { path: 'new-configuration', loadChildren: () => import('./new-configuration/new-configuration.module').then(mod => mod.NewConfigurationModule) , canActivate: [ AuthGuard ]},
  { path: 'reverse-sizing', loadChildren: () => import('./reverse-sizing/reverse-sizing.module').then(mod => mod.ReverseSizingModule) , canActivate: [ AuthGuard ] },
  { path: 'one-pod', loadChildren: () => import('./one-pod/one-pod.module').then(mod => mod.OnePodModule) , canActivate: [ AuthGuard ] },
  { path: 'saved-configurations', loadChildren: () => import('./saved-configuration/saved-configuration.module').then(mod => mod.SavedConfigurationModule), canActivate: [ AuthGuard ] },
  { path: 'create-project', loadChildren: () => import('./create-project/create-project.module').then(mod => mod.CreateProjectModule), canActivate: [ AuthGuard ] },
  { path: 'release-notes', loadChildren: () => import('./components/releaseNotes/release-notes.module').then(m => m.ReleaseNotesModule), canActivate: [AuthGuard] },
  { path: 'faq', loadChildren: () => import('./components/faq/faq.module').then(m => m.FaqModule), canActivate: [AuthGuard] },
  { path: 'auth/callback', component:AuthCallbackComponent },
  { path: '', redirectTo: '/saved-configurations', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
