import { Injectable } from '@angular/core';
import { EnvironmentsService } from './environments.service';

@Injectable({
    providedIn: 'root'
})
export class RecommendationProvider {
    private apiUrl = '';
    
    sfdcUrl = '';
    constructor(private env :EnvironmentsService) {
        this.apiUrl = this.env.config.recommendationServiceUrl;

        this.sfdcUrl = this.env.config.sfdcUrl;
    }
    public getApiUrl() {
        return this.apiUrl;
    }
    
    public getsfdcUrl() {
        return this.sfdcUrl;
    }
}
