// tslint:disable: max-line-length
import { WorkloadProfile } from './recommendation-request';
import { ServerConfiguration, CpuHeadRoom, CapacityOverheads, TwoSite, NPlus, GrowthFactor, StorageEfficiencyPreference } from './cluster-setting-defaults.model';
import { ClusterConfiguration, WorkloadProfileResponse, ServerConfigurationResponse, DiskGroupConfiguration, DiskSpaceUsageOverheads, AdditionalInfo, ResourceStats, ManagementServer, RackCount, Switches, ResourceValues } from './recommendation-response';
import { Partner } from './configuration';
import { VmProfile } from './workload-config';

export class ResourceCounterList {
    type: string;
    counter: number;
    option: any;
}

export class AdditionalHost {
    value: number;
}

export class ResourceCustomization {
    resourceCounterList: ResourceCounterList[];
    additionalHost: AdditionalHost;
}

export class InputJson {
    profileMode: string;
    deployment: string;
    workloadProfiles: WorkloadProfile[];
    serverConfiguration: ServerConfiguration;
    cpuHeadRoom: CpuHeadRoom;
    capacityOverheads: CapacityOverheads;
    twoSite: TwoSite;
    nPlus: NPlus;
    resourceCustomization: ResourceCustomization;
    partners: Partner[];
    nxtEdge: boolean;
    nxtManager: boolean;
    storageEfficiencyPreference: StorageEfficiencyPreference;
    vsanCheck:boolean;
    vSANRNProfile: any;
    selectedReadyNodeId: any;
    tanzuEnabled?: boolean;
    tanzuRecommInput?: any;
}

export class Responsejson {
    clusterConfiguration: ClusterConfiguration;
    workloadProfiles: WorkloadProfileResponse[];
    serverConfiguration: ServerConfigurationResponse;
    diskGroupConfiguration: DiskGroupConfiguration;
    diskSpaceUsageOverheads: DiskSpaceUsageOverheads;
    additionalInfo: AdditionalInfo;
    sizingAssumptions: string[];
    resourceStats: ResourceStats;
    managementServer: ManagementServer;
    rackCount: RackCount;
    switches: Switches;
    resourceValues: ResourceValues;
    tanzuRecommResp?: any;
}

export class ProjectMetaData {
    id: number;
    config: string;
    vcfConfig: string;
    inputJson: InputJson;
    responseJson: Responsejson;
    createdBy: string;
    createdAt: Date;
    updatedBy: string;
    updatedAt: Date;
}

export class MySavedProject {
    projectId: number;
    projectName: string;
    customerName: string;
    customerId: string;
    tags: string;
    description: string;
    sizingType: string;
    status: string;
    createdBy: string;
    createdAt: Date;
    updatedBy: string;
    updatedAt: Date;
    managementInputJson: ManagementPayload;
    managementResponseJson: any;
    savedProjectMetaData: ProjectMetaData;
    savedProjectMetaDataList: Array<any>;
    finalConfig: boolean;
    consolidatedDesign: boolean;
    vcfVersion: string;
    vsanVersionDetails: string;
    migrated:boolean;
}
export class ManagementPayload {
    deployment: string;
    profileMode: string;
    releaseId: number;
    serverConfiguration: ServerConfiguration;
    capacityOverheads: CapacityOverheadsManagements;
    workloadProfiles: Array<any>
    storageEfficiencyPreference: StorageEfficiencyPreference;
    mgmtCluster: boolean;
}
export class CapacityOverheadsManagements {
    growthFactor: GrowthFactor;
}
