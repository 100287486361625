import { Injectable } from '@angular/core';

import { UserService } from './user.service';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private userService: UserService) { }

  canActivate() {
    const user = this.userService.getUser();
    if (user) {
        return true;
    }

    // No user credentials. Redirect to login page.
    this.userService.redirectToLogin();
    return false;
  }
}
