export class VsanReleases {
    releaseVersion: string;
    major: number;
    gaDate: Date;
    sortOrder: number;
    groupId: number;
}

export class TotalSockets {
    value: number;
}

export class CoresPerSocket {
    value: number;
}

export class ClockSpeed {
    value: number;
    units: string;
}

export class MaxDriveSlots {
    value: number;
}

export class CacheTierMediaRating {
    value: number;
}

export class CapacityDriveSize {
    value: number;
    units: string;
}

export class DiskGroup {
    value: string;
}

export class MaxMemory {
    value: number;
    units: string;
}
export class ServerRam {
    value: number;
    units: string;
}

export class UserDefinedDiskGroups {
    value: any;
    
}

export class ServerConfiguration {
    totalSockets: TotalSockets;
    coresPerSocket: CoresPerSocket;
    clockSpeed: ClockSpeed;
    maxDriveSlots: MaxDriveSlots;
    cacheTierMediaRating: CacheTierMediaRating;
    capacityDriveSize: CapacityDriveSize;
    diskGroup: DiskGroup;
    maxMemory: MaxMemory;
    userDefinedDiskGroups: UserDefinedDiskGroups;
    serverRam: ServerRam;
    
}

export class SteadyStateHeadRoom {
    value: number;
}

export class HostFailureHeadRoom {
    value: number;
}

export class CpuHeadRoom {
    steadyStateHeadRoom: SteadyStateHeadRoom;
    hostFailureHeadRoom: HostFailureHeadRoom;
}

export class SlackSpace {
    value: number;
    units: string;
}

export class SwapSpaceEnabled {
    value: boolean;
}

export class GrowthFactor {
    value: number;
    units: string;
}

export class CapacityOverheads {
    slackSpace: SlackSpace;
    swapSpaceEnabled: SwapSpaceEnabled;
    growthFactor: GrowthFactor;
    operationsReserve:SwapSpaceEnabled
}

export class NPlus {
    value: number;
}
export class FileServicesConfiguration{
    fileServicesConfigured:boolean;
}
export class StorageEfficiencyPreference {
    value: string;
}

export class ClusterConfiguration {
    serverConfiguration: ServerConfiguration;
    cpuHeadRoom: CpuHeadRoom;
    capacityOverheads: CapacityOverheads;
    nPlus: NPlus;
}

export class TwoSite {
    value: boolean;
}

export class ClusterSettingDefaults {
    vsanReleases: VsanReleases;
    clusterConfiguration: ClusterConfiguration;
    twoSite: TwoSite;
}
