import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthProvider } from './authProvider.service';
import { environment } from '../../environments/environment';
import { EnvironmentsService } from './environments.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private url: string;

  constructor(private http: HttpClient, private authProvider: AuthProvider , private env:EnvironmentsService) {
    this.url = this.env.config.authApiUrl;
   // this.env.config();
  }

  authenticate(code: string, clientId: string, redirectUri: string) {
    let data = {};
    if (this.env.config.environmentType!== null && this.env.config.environmentType !== undefined && this.env.config.environmentType === 'prod') {
      data = { grant_type: 'authorization_code', code, client_id: clientId, redirect_uri: redirectUri };
    } else {
      data = { grant_type: 'authorization_code', code, client_id: clientId, redirect_uri: redirectUri, bypass_uri_check: true };
    }
    return this.http.post(`${this.url}/tokens`, data);
  }

  refreshToken(token: string) {
    const data = {grant_type: 'refresh_token', refresh_token: token};
    return this.http.post(`${this.env.config.authApiUrl}/csp/gateway/am/api/auth/authorize`, data);
  }

}
