import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentsService {
  private configuration = '${APP_CONFIG}';
  private env: any;
  private nodeEnvironment: string;

  constructor() {
    console.log('configuration in Env Service', this.configuration );

    this.nodeEnvironment =
      this.configuration === '' || this.configuration.startsWith('$')
        ? ''
        : `.${this.configuration}`;
    this.env = require('../../environments/environment' + this.nodeEnvironment);
  }

  get config() {
    return this.env.environment;
  }

  get isDevMode(): boolean {
    return this.nodeEnvironment === '';
  }

  get isTestingMode(): boolean {
    return this.nodeEnvironment === '.test';
  }

  get isStgMode(): boolean {
    return this.nodeEnvironment === '.staging';
  }

  get isProdMode(): boolean {
    return this.nodeEnvironment === '.prod';
  }
}
