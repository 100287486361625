// tslint:disable: max-line-length
import { Injectable } from '@angular/core';
import { config, Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { RecommendationRequest, WorkloadProfile } from '../models/recommendation-request';
import { NPlus, ServerConfiguration, TotalSockets, CoresPerSocket, ClockSpeed, MaxDriveSlots, CacheTierMediaRating, CapacityDriveSize, DiskGroup, MaxMemory, CapacityOverheads, SlackSpace, SwapSpaceEnabled, GrowthFactor, CpuHeadRoom, SteadyStateHeadRoom, HostFailureHeadRoom, UserDefinedDiskGroups, StorageEfficiencyPreference, FileServicesConfiguration, ServerRam } from '../models/cluster-setting-defaults.model';
import { TwoSite } from '../models/cluster-setting-defaults.model';
import { VmProfile, VmdkSize, Raid, VcpuPerCore, VcpuPerVM, VmemPerVM, IopsPerVM, HostFailures, DedupRatio, MasterVMsize, DisksInMasterVM, LinkedClonePool, VmdkGrowthFactor, PersistentDiskSize, SddSize, VmsCount, ExpectedCloneGrowth, InstantClonePools, IoProfile, IoAccessPattern, IoSize, IoRatio, ResourceUtilizationPlan, CpuConsumption, VmPercentage, CpuUtilization, VSANOverheads, CoresConsumed, MaxIOpsPerDisk, VSANIOAmplifier, MaxBandwidthPerDisk } from '../models/workload-config';

@Injectable({
  providedIn: 'root'
})
export class WorkloadProfileService {
  version = '1016'
  configType = 'compressionOnly'
  public notifyWlpCreation: Subject<boolean> = new Subject<boolean>();
  public tanzuSubmit: Subject<boolean> = new Subject<boolean>();
  public versionAbove1018 = ['1018', '1019' ,'1020'];
  constructor() { }

  setNotifyWlpCreation(value: boolean) {
    this.notifyWlpCreation.next(value);
  }
  getNotifyWlpCreation() {
    return this.notifyWlpCreation.asObservable();
  }

  setTanzuSubmit(value: boolean) {
    this.tanzuSubmit.next(value);
  }
  getTanzuSubmit() {
    return this.tanzuSubmit.asObservable();
  }

  generateRecommendationRequest(workloadProfiles: Array<{ wldname: string, name: string, tag: string, workloadForm: UntypedFormGroup, advancedSettingForm: UntypedFormGroup, workloadProfileData: WorkloadProfile }>, deploymentType: string, profileMode: string, culsterSettingsForm: UntypedFormGroup, nxtManager: boolean, nxtEdge: boolean, version, configType) {
    const recommendationRequest: RecommendationRequest = new RecommendationRequest();
    this.version = version;
    if (configType != null && configType != undefined) {
      this.configType = configType;
    }
    recommendationRequest.deployment = deploymentType;
    recommendationRequest.profileMode = profileMode;
    recommendationRequest.nxtManager = nxtManager;
    recommendationRequest.nxtEdge = nxtEdge;
    recommendationRequest.releaseId = Number(version);
    const nplus: NPlus = new NPlus();
    nplus.value = culsterSettingsForm.get('additionalInfo.nPlus').value;
    const twoSite: TwoSite = new TwoSite();
    const fileServicesConfiguration: FileServicesConfiguration = new FileServicesConfiguration();
    fileServicesConfiguration.fileServicesConfigured = false;
    twoSite.value = culsterSettingsForm.get('vsanConfig.stretchedCluster').value;
    const storageEfficiencyPreference: StorageEfficiencyPreference = new StorageEfficiencyPreference();
    storageEfficiencyPreference.value = (configType != null && configType != undefined) ? configType : 'compressionOnly';
    recommendationRequest.fileServicesConfiguration = fileServicesConfiguration;
    recommendationRequest.storageEfficiencyPreference = storageEfficiencyPreference;
    recommendationRequest.nPlus = nplus;
    recommendationRequest.twoSite = twoSite;
    recommendationRequest.serverConfiguration = this.generateServerConfiguration(culsterSettingsForm.get('advanceSettings.serverConfig').value);
    recommendationRequest.capacityOverheads = this.generateCapacityOverheads(culsterSettingsForm.get('additionalInfo').value);
    recommendationRequest.cpuHeadRoom = this.generateCpuHeadRoom(culsterSettingsForm.get('advanceSettings.cpuHeadroom').value);
    const workloadProfileRequest: WorkloadProfile[] = [];
    workloadProfiles.forEach(workload => {
      workloadProfileRequest.push(this.generateWorkloadProfile(workload, configType, version));
    });
    recommendationRequest.workloadProfiles = workloadProfileRequest;
    return recommendationRequest;
  }

  generateServerConfiguration(serverConfig: any) {
    const serverConfiguration: ServerConfiguration = new ServerConfiguration();
    const totalSockets: TotalSockets = new TotalSockets();
    const coresPerSocket: CoresPerSocket = new CoresPerSocket();
    const clockSpeed: ClockSpeed = new ClockSpeed();
    const maxDriveSlots: MaxDriveSlots = new MaxDriveSlots();
    const cacheTierMediaRating: CacheTierMediaRating = new CacheTierMediaRating();
    const capacityDriveSize: CapacityDriveSize = new CapacityDriveSize();
    const diskGroup: DiskGroup = new DiskGroup();
    const maxMemory: MaxMemory = new MaxMemory();
    const userDefinedDiskGroups: UserDefinedDiskGroups = new UserDefinedDiskGroups();
    const serverRam: ServerRam = new ServerRam();
    serverRam.value = serverConfig.serverRam;
    serverRam.units = "GB"
    totalSockets.value = serverConfig.totalSockets;
    coresPerSocket.value = serverConfig.coresPerSocket;
    clockSpeed.value = serverConfig.clockSpeed;
    clockSpeed.units = 'GHz';
    maxDriveSlots.value = serverConfig.maxDriveSlotsAva;
    cacheTierMediaRating.value = serverConfig.CacheTierMediaRat;
    capacityDriveSize.value = serverConfig.macCapacityDriveSize;
    capacityDriveSize.units = 'TB';
    if (serverConfig.diskGroup === 'User Defined DiskGroup') {
      diskGroup.value = serverConfig.userDefinedDiskGroups;
    } else {
      diskGroup.value = serverConfig.diskGroup;
    }
    userDefinedDiskGroups.value = serverConfig.userDefinedDiskGroups;
    serverConfiguration.totalSockets = totalSockets;
    serverConfiguration.coresPerSocket = coresPerSocket;
    serverConfiguration.clockSpeed = clockSpeed;
    serverConfiguration.maxDriveSlots = maxDriveSlots;
    serverConfiguration.cacheTierMediaRating = cacheTierMediaRating;
    serverConfiguration.capacityDriveSize = capacityDriveSize;
    serverConfiguration.diskGroup = diskGroup;
    serverConfiguration.maxMemory = maxMemory;
    serverConfiguration.maxDriveSlots = maxDriveSlots;
    serverConfiguration.userDefinedDiskGroups = userDefinedDiskGroups;
    serverConfiguration.serverRam = serverRam;
    return serverConfiguration;
  }

  generateCapacityOverheads(capacityOverHead: any) {
    const capacityOverHeads: CapacityOverheads = new CapacityOverheads();
    const slackSpace: SlackSpace = new SlackSpace();
    const swapSpaceEnabled: SwapSpaceEnabled = new SwapSpaceEnabled();
    const growthFactor: GrowthFactor = new GrowthFactor();
    const operationsReserve: SwapSpaceEnabled = new SwapSpaceEnabled();
    // if (this.version !== '1018') {
    if (this.versionAbove1018.indexOf(this.version) == -1) {
      slackSpace.value = capacityOverHead.slackSpace;
      slackSpace.units = '%';
      capacityOverHeads.slackSpace = slackSpace;
    } else {
      operationsReserve.value = capacityOverHead.operationsReserve
      capacityOverHeads.operationsReserve = operationsReserve;
    }
    swapSpaceEnabled.value = capacityOverHead.swapSpace;
    growthFactor.value = capacityOverHead.growthFactor;
    growthFactor.units = '%';
    capacityOverHeads.growthFactor = growthFactor;

    capacityOverHeads.swapSpaceEnabled = swapSpaceEnabled;
    return capacityOverHeads;
  }

  generateCpuHeadRoom(cpuHeadRoom: any) {
    const cpuHeadRoomRequest: CpuHeadRoom = new CpuHeadRoom();
    const steadyStateHeadRoom: SteadyStateHeadRoom = new SteadyStateHeadRoom();
    const hostFailureHeadRoom: HostFailureHeadRoom = new HostFailureHeadRoom();
    steadyStateHeadRoom.value = cpuHeadRoom.steadyState;
    hostFailureHeadRoom.value = cpuHeadRoom.hostFailureScenario;
    cpuHeadRoomRequest.hostFailureHeadRoom = hostFailureHeadRoom;
    cpuHeadRoomRequest.steadyStateHeadRoom = steadyStateHeadRoom;
    return cpuHeadRoomRequest;
  }

  generateWorkloadProfile(workloadProfile: { wldname: string, name: string, tag: string, workloadForm: UntypedFormGroup, advancedSettingForm: UntypedFormGroup }, configType, version) {
    const workloadProfileRequest: WorkloadProfile = new WorkloadProfile();
    if (configType != null && configType != undefined) {
      this.configType = configType;
    }
    this.version = version;
    workloadProfileRequest.userSelectedProfileName = workloadProfile.wldname;
    workloadProfileRequest.workload = workloadProfile.name; // (workloadProfile.name === 'TKG') ? 'GPW' : workloadProfile.name;
    workloadProfileRequest.workloadType = workloadProfile.tag;
    workloadProfileRequest.vmProfile = this.generateVmProfile(workloadProfile.workloadForm.get('requiredResourcesGroup.vmProfileDetails').value, workloadProfile.workloadForm.get('requiredResourcesGroup.additionalInfo').value);
    workloadProfileRequest.ioProfile = this.generateIoProfileRequest(workloadProfile.workloadForm.get('requiredResourcesGroup.ioProfile').value);
    workloadProfileRequest.resourceUtilizationPlan = this.generateResourceUtilizationPlan(workloadProfile.advancedSettingForm.get('resourceUtilizationPlan').value);
    if (workloadProfile.advancedSettingForm.get('vsanPerfOverheads').get('coresConsumedperGIops').value != '') {
      workloadProfileRequest.vSANOverheads = this.generateVsanOverHeadRequest(workloadProfile.advancedSettingForm.get('vsanPerfOverheads').value);
    } else {
      if (workloadProfile['workloadProfileData'] && workloadProfile['workloadProfileData'].vSANOverheads)
        workloadProfileRequest.vSANOverheads = workloadProfile['workloadProfileData'].vSANOverheads
    }
    workloadProfileRequest.loProjectId = workloadProfile.workloadForm.get('requiredResourcesGroup.loProjectId').value;
    return workloadProfileRequest;
  }

  generateVmProfile(vmProfile: any, addtionalInfo: any) {
    const vmProfileRequest: VmProfile = new VmProfile();
    const vmdkSize: VmdkSize = new VmdkSize();
    const raid: Raid = new Raid();
    const vcpuPerCore: VcpuPerCore = new VcpuPerCore();
    const vcpuPerVM: VcpuPerVM = new VcpuPerVM();
    const vmemPerVM: VmemPerVM = new VmemPerVM();
    const iopsPerVM: IopsPerVM = new IopsPerVM();
    const hostFailures: HostFailures = new HostFailures();
    const dedupRatio: DedupRatio = new DedupRatio();
    const compression: DedupRatio = new DedupRatio()
    const masterVMsize: MasterVMsize = new MasterVMsize();
    const disksInMasterVM: DisksInMasterVM = new DisksInMasterVM();
    const linkedClonePool: LinkedClonePool = new LinkedClonePool();
    const vmdkGrowthFactor: VmdkGrowthFactor = new VmdkGrowthFactor();
    const persistentDiskSize: PersistentDiskSize = new PersistentDiskSize();
    const sddSize: SddSize = new SddSize();
    const vmsCount: VmsCount = new VmsCount();
    const expectedCloneGrowth: ExpectedCloneGrowth = new ExpectedCloneGrowth();
    const instantClonePools: InstantClonePools = new InstantClonePools();
    vmdkSize.value = vmProfile.storagePerVm;
    vmdkSize.units = 'GB';
    raid.value = addtionalInfo.faultToleranceMethod;
    vcpuPerCore.value = addtionalInfo.vcpuCore;
    vcpuPerVM.value = addtionalInfo.vcpuVM;
    vmemPerVM.value = addtionalInfo.vramVM;
    vmemPerVM.units = 'GB';
    iopsPerVM.value = addtionalInfo.iopsVM;
    hostFailures.value = addtionalInfo.hostFailuresToTolerate;
    dedupRatio.value = addtionalInfo.dedup;
    compression.value = addtionalInfo.compression
    masterVMsize.value = vmProfile.masterVMsize;
    masterVMsize.units = 'GB';
    disksInMasterVM.value = vmProfile.disksInMasterVM;
    linkedClonePool.value = vmProfile.linkedClonePool;
    vmdkGrowthFactor.value = vmProfile.vmdkGrowthFactor;
    vmdkGrowthFactor.units = '%';
    persistentDiskSize.value = vmProfile.persistentDiskSize;
    persistentDiskSize.units = 'GB';
    sddSize.value = addtionalInfo.sddSize;
    sddSize.units = 'GB';
    vmsCount.value = vmProfile.totalCountOfVms;
    expectedCloneGrowth.value = vmProfile.expectedCloneGrowth;
    expectedCloneGrowth.units = '%';
    instantClonePools.value = vmProfile.instantClonePools;
    vmProfileRequest.vmdkSize = vmdkSize;
    vmProfileRequest.raid = raid;
    vmProfileRequest.vcpuPerCore = vcpuPerCore;
    vmProfileRequest.vcpuPerVM = vcpuPerVM;
    vmProfileRequest.vmemPerVM = vmemPerVM;
    vmProfileRequest.iopsPerVM = iopsPerVM;
    vmProfileRequest.hostFailures = hostFailures;
    // if (this.version == '1018' && this.configType == 'compressionOnly') {
    if (this.versionAbove1018.indexOf(this.version) != -1 && this.configType == 'compressionOnly') {
      vmProfileRequest.compression = compression
      dedupRatio.value = 1;
    }
    vmProfileRequest.dedupRatio = dedupRatio;
    vmProfileRequest.masterVMsize = masterVMsize;
    vmProfileRequest.disksInMasterVM = disksInMasterVM;
    vmProfileRequest.linkedClonePool = linkedClonePool;
    vmProfileRequest.vmdkGrowthFactor = vmdkGrowthFactor;
    vmProfileRequest.persistentDiskSize = persistentDiskSize;
    vmProfileRequest.sddSize = sddSize;
    vmProfileRequest.vmsCount = vmsCount;
    vmProfileRequest.expectedCloneGrowth = expectedCloneGrowth;
    vmProfileRequest.instantClonePools = instantClonePools;
    return vmProfileRequest;
  }

  generateIoProfileRequest(ioProfile: any) {
    const ioProfileRequest: IoProfile = new IoProfile();
    const ioAccessPattern: IoAccessPattern = new IoAccessPattern();
    const ioSize: IoSize = new IoSize();
    const ioRatios: IoRatio = new IoRatio();
    ioAccessPattern.value = ioProfile.ioAccessPattern;
    ioSize.value = ioProfile.ioSize;
    ioSize.units = 'KB';
    ioRatios.value = ioProfile.ioRatio;
    ioProfileRequest.ioAccessPattern = ioAccessPattern;
    ioProfileRequest.ioRatios = ioRatios;
    ioProfileRequest.ioSize = ioSize;
    return ioProfileRequest;
  }

  generateResourceUtilizationPlan(resourceUtilizationPlan: any) {
    const resourceUtilizationPlanRequest: ResourceUtilizationPlan = new ResourceUtilizationPlan();
    const vmPercentage: VmPercentage[] = [];
    const cpuUtilization: CpuUtilization[] = [];
    const cpuConsumption: CpuConsumption = new CpuConsumption();
    cpuConsumption.value = resourceUtilizationPlan.restVmsRunAt;
    cpuConsumption.units = '%';
    const vmPercentageRequest = new VmPercentage();
    vmPercentageRequest.units = '%';
    vmPercentageRequest.value = resourceUtilizationPlan.vms;
    const cpuUtilizationRequest: CpuUtilization = new CpuUtilization();
    cpuUtilizationRequest.value = resourceUtilizationPlan.runAt;
    cpuUtilizationRequest.units = '%';
    vmPercentage.push(vmPercentageRequest);
    cpuUtilization.push(cpuUtilizationRequest);
    resourceUtilizationPlanRequest.cpuConsumption = cpuConsumption;
    resourceUtilizationPlanRequest.cpuUtilization = cpuUtilization;
    resourceUtilizationPlanRequest.vmPercentage = vmPercentage;
    return resourceUtilizationPlanRequest;
  }

  generateVsanOverHeadRequest(vsanOverHead: any) {
    const vsanOverHeadRequest: VSANOverheads = new VSANOverheads();
    const coresConsumed: CoresConsumed = new CoresConsumed();
    const maxIOpsPerDisk: MaxIOpsPerDisk = new MaxIOpsPerDisk();
    const capacityWriteIOAmplifier: VSANIOAmplifier = new VSANIOAmplifier();
    const cacheWriteIOAmplifier: VSANIOAmplifier = new VSANIOAmplifier();
    const maxBandwidthPerDisk: MaxBandwidthPerDisk = new MaxBandwidthPerDisk();
    coresConsumed.value = vsanOverHead.coresConsumedperGIops;
    maxIOpsPerDisk.value = vsanOverHead.vsanLimitMaxIopsperDiskgrp;
    capacityWriteIOAmplifier.value = vsanOverHead.capacityWriteIOAmplifier;
    cacheWriteIOAmplifier.value = vsanOverHead.cacheWriteIOAmplifier;
    maxBandwidthPerDisk.value = vsanOverHead.maxBandwidthPerDisk;
    maxBandwidthPerDisk.units = 'MB/s';
    vsanOverHeadRequest.coresConsumed = coresConsumed;
    vsanOverHeadRequest.maxBandwidthPerDisk = maxBandwidthPerDisk;
    vsanOverHeadRequest.maxIOpsPerDisk = maxIOpsPerDisk;
    vsanOverHeadRequest.capacityWriteIOAmplifier = capacityWriteIOAmplifier;
    vsanOverHeadRequest.cacheWriteIOAmplifier = cacheWriteIOAmplifier;
    return vsanOverHeadRequest;
  }

  generateTanzuRelatedProfile(profile: { name: string, vmCount: string, vcpuPerVM: string, vmmdkSize: string, vmmdkUnit: string, vmemPerVM: string, vmemUnit: string, vmSize: string }, workloadProfileForm: UntypedFormGroup, wrkloadType: string, workloadDomainConfig: UntypedFormGroup, compresionType) {
    let tanzuObject = {
      name: profile.name,
      workload: workloadProfileForm.get('workload').value, // 'GPW',
      workloadType: wrkloadType,
      vmProfile: {
        vmdkSize: { value: profile.vmmdkSize, units: profile.vmmdkUnit },
        vmsCount: { value: profile.vmCount },
        vcpuPerVM: { value: profile.vcpuPerVM },
        vmemPerVM: { value: profile.vmemPerVM, units: profile.vmemUnit },
        vmSize: { value: (profile.vmSize) ? profile.vmSize : '0' },
        compression: {
          value: 1,
        },
        dedupRatio: {
          value: 1
        }
      }
    }
    if (compresionType === 'compressionOnly') {
      tanzuObject.vmProfile.compression.value = workloadDomainConfig.get('requiredResourcesGroup.additionalInfo.compression').value;
      tanzuObject.vmProfile.dedupRatio.value = 1;
    } else if (compresionType === 'dedup') {
      tanzuObject.vmProfile.dedupRatio.value = workloadDomainConfig.get('requiredResourcesGroup.additionalInfo.dedup').value;
      delete tanzuObject.vmProfile.compression;
    } else if (compresionType === 'none') {
      tanzuObject.vmProfile.dedupRatio.value = 1;
      delete tanzuObject.vmProfile.compression;
    }
    return tanzuObject;
  }

  generateTanzuProfileOnePod(profile: { name: string, vmCount: string, vcpuPerVM: string, vmmdkSize: string, vmmdkUnit: string, vmemPerVM: string, vmemUnit: string, vmSize: string }, workloadProfileForm: UntypedFormGroup, wrkloadType: string, workloadDomainConfig: UntypedFormGroup, compresionType) {
    let tanzuObject = {
      name: profile.name,
      workload: workloadProfileForm.get('workload').value, // 'GPW',
      workloadType: wrkloadType,
      mgmtWorkload: false,
      vmProfile: {
        vmdkSize: { value: profile.vmmdkSize, units: profile.vmmdkUnit },
        vmsCount: { value: profile.vmCount },
        vcpuPerVM: { value: profile.vcpuPerVM },
        vmemPerVM: { value: profile.vmemPerVM, units: profile.vmemUnit },
        vmSize: { value: (profile.vmSize) ? profile.vmSize : '0' },
        compression: {
          value: 1,
        },
        dedupRatio: {
          value: 1
        }
      }
    }
    if (compresionType === 'compressionOnly') {
      tanzuObject.vmProfile.compression.value = workloadDomainConfig.get('requiredResourcesGroup.additionalInfo.compression').value;
      tanzuObject.vmProfile.dedupRatio.value = 1;
    } else if (compresionType === 'dedup') {
      tanzuObject.vmProfile.dedupRatio.value = workloadDomainConfig.get('requiredResourcesGroup.additionalInfo.dedup').value;
      delete tanzuObject.vmProfile.compression;
    } else if (compresionType === 'none') {
      tanzuObject.vmProfile.dedupRatio.value = 1;
      delete tanzuObject.vmProfile.compression;
    }
    return tanzuObject;
  }
  
}
