import { VmProfile, IoProfile, ResourceUtilizationPlan, VSANOverheads } from './workload-config';
import { CpuHeadRoom, ServerConfiguration, NPlus, CapacityOverheads, TwoSite, StorageEfficiencyPreference, FileServicesConfiguration } from './cluster-setting-defaults.model';
import { ResourceCustomization } from './my-saved-project.model';

export class WorkloadProfile {
    vmProfile: VmProfile;
    ioProfile: IoProfile;
    resourceUtilizationPlan: ResourceUtilizationPlan;
    vSANOverheads: VSANOverheads;
    workload: string;
    workloadType: string;
    userSelectedProfileName: string;
    loProjectId: number;
}

export class RecommendationRequest {
    deployment: string;
    profileMode: string;
    releaseId: number;
    nxtEdge: boolean;
    nxtManager: boolean;
    workloadProfiles: WorkloadProfile[];
    serverConfiguration: ServerConfiguration;
    nPlus: NPlus;
    cpuHeadRoom: CpuHeadRoom;
    capacityOverheads: CapacityOverheads;
    twoSite: TwoSite;
    resourceCustomization: ResourceCustomization;
    storageEfficiencyPreference: StorageEfficiencyPreference;
    fileServicesConfiguration: FileServicesConfiguration;
    tanzuEnabled?: any;
    tanzuRecommInput?: {
        clusters: [],
        defaultSoftwares: []
        supervisor: any
    };
}

