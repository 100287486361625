import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { EnvironmentsService } from './environments.service';

@Injectable({
    providedIn: 'root'
})
export class MyProjectsProvider {
    private apiUrl = '';
    constructor(private env:EnvironmentsService) {
        this.apiUrl = this.env.config.recommendationServiceUrl;
    }
    public getApiUrl() {
        return this.apiUrl;
    }
}
