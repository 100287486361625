// tslint:disable: max-line-length
import { Injectable } from '@angular/core';
import { MySavedProject, ProjectMetaData, InputJson, Responsejson, ResourceCounterList, ResourceCustomization } from '../models/my-saved-project.model';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { WorkloadProfileService } from './workload-profile.service';
import { RecommendationRequest, WorkloadProfile } from '../models/recommendation-request';
import { RecommendationResponse } from '../models/recommendation-response';
import { Partner } from '../models/configuration';
import { ItemList } from '../models/itemList';
import { ServerConfiguration } from '../models/cluster-setting-defaults.model';
import { RecommendationService } from './recommendation.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  editProject: boolean;
  private manipulateProjectSubject = new Subject<MySavedProject>();
  configTypeSubject: any;
  clusterData: any;
  private newProject: MySavedProject;
  private workloadProfileData: WorkloadProfile;
  private workloadIndex: number;
  private apiError: boolean;
  private errorMessage: string;
  private projectName: string;
  clusterSetting: any;
  private dataSource = new BehaviorSubject<MySavedProject>(null);
  compType = new BehaviorSubject<string>('compressionOnly');
  data = this.dataSource.asObservable();
  private loader = new BehaviorSubject<boolean>(false);
  load = this.loader.asObservable();
  private updateVersion = new BehaviorSubject<boolean>(false);
  updateversion = this.updateVersion.asObservable();
  projectVersion = '1016';
  vsanVersion = 'vSAN 6.7 U3';
  hideLO = true;
  defaultSoftwares: any;
  createOrupdateWorkload: boolean;
  constructor(private workloadProfileService: WorkloadProfileService,private recommService: RecommendationService,) {
    this.editProject = false;
  }

  setManipProject(project: MySavedProject) {
    this.manipulateProjectSubject.next(project);
  }
  getManipProject(): Observable<MySavedProject> {
    return this.manipulateProjectSubject.asObservable();
  }
  setConfigType(project: any) {
    this.configTypeSubject = project;
  }
  getConfigType() {
    return this.configTypeSubject;
  }
  setClusterSetting(culsterSettingsForm) {
    this.clusterSetting = culsterSettingsForm;
  }
  getClusterSetting() {
    return this.clusterSetting;
  }

  setClusterData(project: any) {
    this.clusterData = project;
  }
  getClusterData() {
    return this.clusterData;
  }
  setProjectName(project: string) {
    this.projectName = project;
  }
  getProjectName(): string {
    return this.projectName;
  }
  setLoader(value: boolean) {
    this.loader.next(value);
  }
  setupdateVersionFlag(value: boolean) {
    this.updateVersion.next(value)
  }

  setNewProject(project: MySavedProject) {
    if (project.vsanVersionDetails) {
      this.projectVersion = project.vsanVersionDetails.split('_')[1];
      this.vsanVersion = project.vsanVersionDetails.split('_')[0].toLowerCase();

    } else {
      this.projectVersion = '1016';
      this.vsanVersion = 'vsan 6.7 u3'
    }
    this.newProject = project;
    this.dataSource.next(project);
  }

  getNewReverseSizerProject() {
    return this.newProject;
  }

  setNewReverseSizerProject(project: MySavedProject) {
    this.newProject = project;
  }

  getNewProject() {
    return this.newProject;
  }

  setWorkloadProfileData(workloadProfileData: WorkloadProfile) {
    this.workloadProfileData = workloadProfileData;
  }

  getWorkloadProfileData() {
    return this.workloadProfileData;
  }

  getWorkloadIndex() {
    return this.workloadIndex;
  }

  setWorkloadIndex(index: number) {
    this.workloadIndex = index;
  }

  getApiError() {
    return this.apiError;
  }

  setApiError(apiError: boolean) {
    this.apiError = apiError;
  }

  getApiErrorMessage() {
    return this.errorMessage;
  }

  setApiErrorMessage(message: string) {
    this.errorMessage = message;
  }

  createSaveProjectMetadataRequest(number, certifiedvSANObj, projectData: MySavedProject, workloadProfiles: Array<{ wldname: string, name: string, tag: string, workloadForm: UntypedFormGroup, advancedSettingForm: UntypedFormGroup, workloadProfileData: WorkloadProfile }>, deploymentType: string, profileMode: string, culsterSettingsForm: UntypedFormGroup, userName: string, vcfConfig: string, recommendationResponse: RecommendationResponse, resourceCounterList: ResourceCounterList[], partners: Partner[], tanzuEnabled?, tanzuRecommInput?, tanzuRecommResp?) {
    let projectMetadata: ProjectMetaData;
    if (projectData.savedProjectMetaData !== undefined && projectData.savedProjectMetaData !== null) {
      projectMetadata = projectData.savedProjectMetaData;
    } else {
      projectMetadata = new ProjectMetaData();
      projectMetadata.createdAt = new Date();
      projectMetadata.createdBy = userName;
    }
    projectMetadata.config = profileMode;
    projectMetadata.updatedAt = new Date();
    projectMetadata.updatedBy = userName;
    projectMetadata.vcfConfig = vcfConfig;
    projectMetadata.createdBy = userName;
    projectData.status = 'In Progress';
    projectData.updatedAt = new Date();
    projectData.updatedBy = userName;
    projectMetadata.inputJson = this.createSavedProjectMetadataInputJson(workloadProfiles, deploymentType, profileMode, culsterSettingsForm, resourceCounterList, partners);
    
    if(this.createOrupdateWorkload && certifiedvSANObj !== null){
      this.createOrupdateWorkload = false;
      projectMetadata.inputJson.vsanCheck = certifiedvSANObj.certifiedvSANCheck;
      if(projectMetadata.inputJson.vsanCheck){
        projectMetadata.inputJson.vSANRNProfile = certifiedvSANObj.selectedVSAN;
        projectMetadata.inputJson.selectedReadyNodeId = certifiedvSANObj.selectedReadyNode;
      }
    }else{
      projectMetadata.inputJson.vsanCheck = projectData.savedProjectMetaDataList[number].inputJson.vsanCheck;
      if(projectMetadata.inputJson.vsanCheck){
        projectMetadata.inputJson.vSANRNProfile = projectData.savedProjectMetaDataList[number].inputJson.vSANRNProfile;
        projectMetadata.inputJson.selectedReadyNodeId = projectData.savedProjectMetaDataList[number].inputJson.selectedReadyNodeId; 
      }
    }
    if (recommendationResponse !== undefined && recommendationResponse !== null) {
      projectMetadata.responseJson = this.createSavedProjectMetadataResponse(recommendationResponse);
      projectData.status = 'Completed';
    } else {
      projectMetadata.responseJson = null;
    }
    // add tanzu json
    if (tanzuEnabled) {
      projectMetadata.inputJson.tanzuEnabled = tanzuEnabled;
      projectMetadata.inputJson.tanzuRecommInput = tanzuRecommInput;
      projectMetadata.responseJson.tanzuRecommResp = tanzuRecommResp;
    }
    // add tanzu json
    projectData.savedProjectMetaData = null;
    if (projectData.savedProjectMetaDataList == null) {
      projectData.savedProjectMetaDataList = [];
    }
    if (number !== null) {
      let id = JSON.parse(JSON.stringify(projectData.savedProjectMetaDataList[number].id))
      projectData.savedProjectMetaDataList[number] = projectMetadata;
      projectData.savedProjectMetaDataList[number].id = id;
      if (projectData.managementResponseJson.errorWorkload && projectData.managementResponseJson.errorWorkload.length > 0) {
        projectData.managementResponseJson.errorWorkload = projectData.managementResponseJson.errorWorkload.filter(x => x.id != id)
      }
    } else {
      projectData.savedProjectMetaDataList.push(projectMetadata);
    }
    return projectData;
  }

  createSavedProjectMetadataInputJson(workloadProfiles: Array<{ wldname: string, name: string, tag: string, workloadForm: UntypedFormGroup, advancedSettingForm: UntypedFormGroup, workloadProfileData: WorkloadProfile }>, deploymentType: string, profileMode: string, culsterSettingsForm: UntypedFormGroup, resourceCounterList: ResourceCounterList[], partners: Partner[]) {
    const inputJson: InputJson = new InputJson();
    let configType = this.getConfigType();
    const recommendationRequest: RecommendationRequest = this.workloadProfileService.generateRecommendationRequest(workloadProfiles, deploymentType, profileMode, culsterSettingsForm, configType.nsxtManager, configType.nsxtEdge, this.projectVersion, configType.compType);
    if (resourceCounterList !== null && resourceCounterList !== undefined && resourceCounterList.length > 0) {
      const resourceCustomization: ResourceCustomization = new ResourceCustomization();
      resourceCustomization.resourceCounterList = resourceCounterList;
      recommendationRequest.resourceCustomization = resourceCustomization;
    }
    inputJson.capacityOverheads = recommendationRequest.capacityOverheads;
    inputJson.cpuHeadRoom = recommendationRequest.cpuHeadRoom;
    inputJson.deployment = deploymentType;
    inputJson.nPlus = recommendationRequest.nPlus;
    inputJson.profileMode = profileMode;
    inputJson.resourceCustomization = recommendationRequest.resourceCustomization;
    inputJson.serverConfiguration = recommendationRequest.serverConfiguration;
    inputJson.twoSite = recommendationRequest.twoSite;
    inputJson.workloadProfiles = recommendationRequest.workloadProfiles;
    inputJson.partners = partners;
    inputJson.nxtEdge = recommendationRequest.nxtEdge;
    inputJson.nxtManager = recommendationRequest.nxtManager;
    inputJson.storageEfficiencyPreference = recommendationRequest.storageEfficiencyPreference
    return inputJson;
  }

  createSavedProjectMetadataResponse(recommendationResponse: RecommendationResponse) {
    const responseJson: Responsejson = new Responsejson();
    responseJson.additionalInfo = recommendationResponse.additionalInfo;
    responseJson.clusterConfiguration = recommendationResponse.clusterConfiguration;
    responseJson.diskGroupConfiguration = recommendationResponse.diskGroupConfiguration;
    responseJson.diskSpaceUsageOverheads = recommendationResponse.diskSpaceUsageOverheads;
    responseJson.managementServer = recommendationResponse.managementServer;
    responseJson.rackCount = recommendationResponse.rackCount;
    responseJson.resourceStats = recommendationResponse.resourceStats;
    responseJson.serverConfiguration = recommendationResponse.serverConfiguration;
    responseJson.sizingAssumptions = recommendationResponse.sizingAssumptions;
    responseJson.switches = recommendationResponse.switches;
    responseJson.workloadProfiles = recommendationResponse.workloadProfiles;
    responseJson.resourceValues = recommendationResponse.resoruceValues;
    return responseJson;
  }

  createRecommendationResponse(responseJson: Responsejson, inputJson: InputJson) {
    const recommendationResponse = new RecommendationResponse();
    recommendationResponse.additionalInfo = responseJson.additionalInfo;
    recommendationResponse.clusterConfiguration = responseJson.clusterConfiguration;
    recommendationResponse.diskGroupConfiguration = responseJson.diskGroupConfiguration;
    recommendationResponse.diskSpaceUsageOverheads = responseJson.diskSpaceUsageOverheads;
    recommendationResponse.managementServer = responseJson.managementServer;
    recommendationResponse.rackCount = responseJson.rackCount;
    recommendationResponse.resourceStats = responseJson.resourceStats;
    recommendationResponse.serverConfiguration = responseJson.serverConfiguration;
    recommendationResponse.sizingAssumptions = responseJson.sizingAssumptions;
    recommendationResponse.switches = responseJson.switches;
    recommendationResponse.workloadProfiles = responseJson.workloadProfiles;
    recommendationResponse.clusterConfiguration.strechedCluster = inputJson.twoSite.value;
    recommendationResponse.resoruceValues = responseJson.resourceValues;
    return recommendationResponse;
  }

  addNSXTEdgeSoftware(compresionType,recommendationRequest,vmProfile,defaulSoftwarelist){
    let defaultSoftware = JSON.parse(JSON.stringify(defaulSoftwarelist));
      for (let i = 0; i < defaultSoftware.length; i++) {
        if (defaultSoftware[i].id == 15) {
          let value = {
            name:'NSX-T Edge',
            workload: 'GPW',
            workloadType: 'GVM',
            vmProfile: {
              vmdkSize: defaultSoftware[i].vmdkSize,
              dedupRatio: {
                value: 1
              },
              compression: {
                value: 1,
              },
              vmsCount: defaultSoftware[i].vmsCount,
              vcpuPerVM: defaultSoftware[i].vcpuPerVM,
              vmemPerVM: defaultSoftware[i].vmemPerVM
            }
          }
          this.setCompressionAndDedup(compresionType,value,vmProfile)
          recommendationRequest.workloadProfiles.push(value);
        }
      }
  }

  addTanzuSoftwares(software, recommendationRequest,workload, workloadType, compresionType,vmProfile) {
    let tanzuObject = {
      name: software.name,
      workload: workload, 
      workloadType: workloadType,
      vmProfile: {
        vmdkSize: { value: software.vmmdkSize, units: software.vmmdkUnit },
        vmsCount: { value: software.vmCount },
        vcpuPerVM: { value: software.vcpuPerVM },
        vmemPerVM: { value: software.vmemPerVM, units: software.vmemUnit },
        vmSize: { value: (software.vmSize) ? software.vmSize : '0' },
        compression: {
          value: 1,
        },
        dedupRatio: {
          value: 1
        }
      }
    }
    this.setCompressionAndDedup(compresionType,tanzuObject,vmProfile);
    recommendationRequest.workloadProfiles.push(tanzuObject);
  }

  setCompressionAndDedup(compresionType,value,vmProfile){
    if (compresionType === 'compressionOnly') {
      value.vmProfile.compression.value = vmProfile.compression.value;
      value.vmProfile.dedupRatio.value = 1;
    } else if (compresionType === 'dedup') {
      value.vmProfile.dedupRatio.value = vmProfile.dedupRatio.value;
      delete value.vmProfile.compression;
    } else if (compresionType === 'none') {
      value.vmProfile.dedupRatio.value = 1;
      delete value.vmProfile.compression;
    }
  }

  assignServerConfigData(clusterSettingsForm,resp){
    clusterSettingsForm.get('advanceSettings.serverConfig.totalSockets').setValue(resp.clusterConfiguration.serverConfiguration.totalSockets.value);
    clusterSettingsForm.get('advanceSettings.serverConfig.coresPerSocket').setValue(resp.clusterConfiguration.serverConfiguration.coresPerSocket.value);
    clusterSettingsForm.get('advanceSettings.serverConfig.clockSpeed').setValue(resp.clusterConfiguration.serverConfiguration.clockSpeed.value);
    clusterSettingsForm.get('advanceSettings.serverConfig.maxDriveSlotsAva').setValue(resp.clusterConfiguration.serverConfiguration.maxDriveSlots.value);
    clusterSettingsForm.get('advanceSettings.serverConfig.macCapacityDriveSize').setValue(resp.clusterConfiguration.serverConfiguration.capacityDriveSize.value);
  }

  prepareVendors(actionOnWlp){
    let obj1 = {
      dropdownList : [],
      selectedItems : []
    }
    obj1.dropdownList = this.getConfigType().dropdownList;
    if (obj1.selectedItems.length == 0 && actionOnWlp === 'create') {
      let obj = {
        id: 23,
        itemName: "Dell Inc."
      }
      obj1.selectedItems.push(obj);
    }
    return obj1;
  }

  assignVendors(configurationInput){
    let dropdownList = [];
    configurationInput.forEach(partner => {
      const itemList: ItemList = new ItemList();
      itemList.id = partner.partnerId;
      itemList.itemName = partner.partnerName;
      dropdownList.push(itemList);
    });
    return dropdownList;
  }

  prepareReayNodePartnerRequestObj(partnerIds,selectedVSAN,vsanVersion,readynodePartnerRequest){
    readynodePartnerRequest.partnerId = partnerIds;
    readynodePartnerRequest.profileId = [+selectedVSAN];
    readynodePartnerRequest.release = [vsanVersion]
  }
}
