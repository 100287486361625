// tslint:disable: max-line-length
export const environment = {
    production: false,
    apiUrl: 'https://auth.esp-staging.vmware-aws.com/api/auth/v1',
    inputServiceUrl: 'https://eapi-gcpstg.broadcom.com/verify/vcf/tools/input',
    rvToolUrl: 'https://eapi-gcpstg.broadcom.com/verify/vcf/tools/input',
    recommendationServiceUrl: 'https://eapi-gcpstg.broadcom.com/verify/vcf/tools/recomm',
    sfdcUrl : 'https://eapi-gcpstg.broadcom.com/verify/vcf/tools/cmds',
    CLIENT_ID: 'esp-stg-179-wybmx',
    environmentType: 'stage',
    trackingId: '',
    featureModule: 'saved-configurations',
    orgId : 'cd5f8911-03fc-4cc0-b4e0-a532af2c6c08',
    codeChallenge: '7GqmC3rOWGkyEDBlxSVh6xsLi0LZcpnMgtRps_B0Mw8',
    codeChallengeMethod:'S256',
    codeVerifier:'u1adRVd1-dUNkDWcjPF07VOedFXJSX9vH6wu3NO8fP2sADqDcOUM-8Tp_WiT73iUE9m3o8bxYQn2SDp0B_4ZZOj3nY5ctpwWrdlJZeBsvJSsXfm1aOhugnh2v69bbmeV',
    authApiUrl: 'https://console-stg.cloud.vmware.com',
    STATE: 'CXNwLWFwaS1jbGllbnQ=',
};
