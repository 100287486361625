// tslint:disable: max-line-length
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppStringsService {

  appStrings: any;
  constructor() {
    this.appStrings = {
      title: 'Cloud Foundation Capacity Planner',
      stchThm: 'Switch Theme',
      signOut: 'Sign Out',
      signin: 'Sign In',
      home: 'Home',
      savedConfig: 'My Saved Projects',
      revSizing: 'Reverse Sizing',
      newConfig: 'New',
      help: 'Help',
      support: 'Support',
      faq: 'FAQs',
      close: 'Close',
      impLinks: 'Important VCF Links',
      accInfo: 'Account Info',
      profile: 'Profile',
      changePwd: 'Change Password',
      save: 'Save',
      cancel: 'Cancel',
      send: 'send',
      reset: 'reset',
      empSignIn: 'Employee Sign In',
      pwdInstruct: 'Password Reset Instruction',
      forgotPwd: 'Forgot Password',
      persistentDktopNote: 'Allowed range for Persistent Disk(GB): 0 to 100. Note that extra storage will be added to VMs for the system drive. We assume linked desktops, so the first VM will add 60 GB and all other VMs will add 5 GB for the system drive.',
      selectVendor: 'Select Vendor',
      readynodeRecommender: 'ReadyNode Recommender',
      vendor: 'Vendor',
      model: 'Model',
      profileCapacity: 'Profile & Capacity',
      actions: 'Actions',
      partner: 'Partner',
      diskGroupDistributionMethodInfoIcon: `Select the disk group distribution method for your servers.There are 3 options for this:<br>
      1. Maximum: This will determine the maximum possible number of disk groups which can be packed into a server as well as
        the optimal number of drives per disk group.<br>
          2. Minimum: This method will determine the least number of disk groups which can be packed into a server based on your
    workloads.Can be used to validate the ReadyNode profile alignment with vSAN's hardware guidance.<br>
    3. User - defined: A user will input the number of disk groups he would like to design per server.The Sizer will then
    only use this configuration to determine the drive layout, even if there are additional drive slots which can be used to
    pack in more disk groups into a server.<br>
      Refer to the FAQ section for more details.`,
      AF: 'All Flash',
      HY: 'Hybrid',
      dsd: 'Data Center Scale',
      robo: 'ROBO Scale',
      diskSpaceUsageInfoIcon: 'The Disk Space Usage Distribution is made up of the following components:<br>Effective Used Capacity: Space Used for VM space with RAID, space-efficiency, and growth factor.<br>Slack Space: Space reserved for vSAN specific operations such as resync, rebuilds, etc.<br>Dedup Overhead: Space reserved for dedup/compression metadata such as hash, translation and allocation maps<br>Disk formatting Overhead: Reservation for FS metadata<br>Checksum Overhead: Space used to checksum information<br>File Services Overheads: How much physical space/raw capacity do we consume as part of the overheads such as FS and features such as dedup, compression, etc.<br>Free Space: Remaining physical disk space available for future growth. <br>Remaining Free Space: Physical disk space remaining in a cluster after all the vSAN capacity allocation are complete.',
      FCL: 'Full Clone',
      LCL: 'Linked Clone',
      ICL: 'Instant Clone',
      ORA: 'Oracle',
      SQL: 'Microsoft SQL Server',
      GVM: 'General VMs',
      VDI: 'VDI',
      DBW: 'Databases',
      GPW: 'General Purpose',
      intelOptaneInfo: 'Built on a unique architecture that allows memory cells to be individually addressed in a dense, transistor-less, stackable design, Intel® Optane™ technology offers the exceptional combination of low latency, high endurance and consistent responsiveness—ideal for fast caching or fast storage.',
      TKG: 'Tanzu',
      TKGK8: 'TKG k8 Clusters'
    };
  }
}
