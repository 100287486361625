import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    constructor(private userService: UserService) {
  
    }
  
    redirectToLogin() {
      this.userService.redirectToLogin();
    }
  

  ngOnInit(): void {
  }

}